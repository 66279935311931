import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';

import settingsParams from '../settingsParams';

import { ISection, SectionTypes } from '../types';

export const useWidgetSections = (): ISection[] => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const isAddSlotPlaceholderOnServicePageEnabled = experiments.enabled(
    'specs.bookings.addSlotPlaceholderOnServicePage',
  );

  let sections: ISection[] = settings
    .get(settingsParams.sections)
    .filter((section) => section);

  if (sections.map(({ type }) => type).indexOf(SectionTypes.GALLERY) === -1) {
    sections.push({
      type: SectionTypes.GALLERY,
      visible: true,
      bookButton: false,
    });
  }

  if (!isAddSlotPlaceholderOnServicePageEnabled) {
    sections = sections.filter(
      (section) => section.type !== SectionTypes.PLUGIN,
    );
  } else {
    if (sections.map(({ type }) => type).indexOf(SectionTypes.PLUGIN) === -1) {
      sections.push({
        type: SectionTypes.PLUGIN,
        visible: false,
        bookButton: false,
      });
    }
  }

  if (isMobile) {
    const mobileSections = settings.get(settingsParams.mobileSections);
    if (mobileSections) {
      sections.sort(
        ({ type: firstSectionType }, { type: secondSectionType }) => {
          return (
            mobileSections.indexOf(firstSectionType) -
            mobileSections.indexOf(secondSectionType)
          );
        },
      );
    }
  }

  return sections;
};
